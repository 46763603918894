<template>
  <div>
    <div>
      <navbar />
    </div>
    <!-- 中间内容 -->
    <!-- #fafafa -->
    <div class="middle">
      <div class="middle-head">
        <div class="head-left">
          <span class="left" @click="goOnlineExpert">Home</span>
          <span class="arrow">></span>
          <span class="left-frite">F.A.Q</span>
        </div>
        <!-- <div class="head-right">
          <el-input v-model="state.searchValue" class="search-input" :suffix-icon="Search"
            placeholder="Search for experts and categories" @change="getSearch(state.searchValue)" />
        </div> -->
      </div>
      <div class="content-box">
        <div class="head-title">
          <h2>Frequently Asked Questions</h2>
        </div>
        <ul class="faq-list">
          <el-collapse class="box-collapse" v-model="activeCollapse" accordion @change="handleCollapseChange">
              <el-collapse-item v-for="(item, index) in faqContents" :key="index" :title="item.question" :name="index" class="custom-collapse-item" :class="{ 'selected': isActive(index) }">
                <div class="font-answer" v-html="item.answer" />
              </el-collapse-item>
          </el-collapse>
        </ul>
      </div>
      <div>
        <img src="@/assets/web_permissions.png" hidden>
      </div>
    </div>
    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script setup>
import Navbar from "../.././layout/components/Navbar.vue";
import Footer from "../.././layout/components/Footer.vue";
import { Search } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { reactive, onMounted, ref } from "vue";
const router = useRouter();
const activeCollapse = ref("");
const pageTitle = ref("");
const faqContents = [
  {
    question: "How can I use my coupon?",
    answer: "1) Select the advisor that you want to consult." +
      "\n2) Tap on \"Start Session\"." +
      "\n3) Tap on \"Use coupons\"." +
      "\n4) Select the coupon that you want to use and tap on \"Confirm\"." +
      "\n5) The selected coupon will be selected." +
      "\n6) Tap on \"Call Now\" and the coupon will be used."
  },
  {
    question: "How can I change my profile?",
    answer: "After logging in, expand the User Menu on the top right of the screen. Tap on Account detail. You can update your avatar, username, and password here."
  },
  {
    question: "I forgot my password. How do I log in?",
    answer: "If you've forgotten your password:" +
      "\n1) Tap the \"I forgot my password\" text on the login page." +
      "\n2) Enter your email address and tap on \"Send\"." +
      "\n3) Directions for resetting your password will then be sent to your email." +
      "\n\nIf you registered your account using Facebook or Google login, you won't have a password to reset. Simply tap on the Facebook or Google login to access your account."
  },
  {
    question: "I'm calling for the first time. What should I do?",
    answer: "When prompted, please tap \"Allow\" to grant the necessary permissions to Zest Wisdom." +
      "\nAlternatively, you can tap on the icon beside the URL bar and grant the necessary permissions as below:" +
      "\n<img src=\"/img/web_permissions.8ad3b4e9.png\">"
  },
  {
    question: "I'm having trouble connecting to the advisors. What should I do?",
    answer: "If your call request can't go through or you can't see the advisor at all, please try the following steps:" +
      "\n1) Hang up or close any call dialog." +
      "\n2) Refresh your browser." +
      "\n3) Log out of Zest Wisdom." +
      "\n4) Close and reopen your browser." +
      "\n5) Log in to Zest Wisdom and try to call again." +
      "\n\nIf you are still having troubles after performing the steps above, please email support using your registered email with the following information:" +
      "\n1) The device that you are using to call the advisor." +
      "\n2) Your network speed." +
      "\n3) Name of the advisor you are trying to call."
  },
  {
    question: "How do I know if these are real advisors?",
    answer: "Zest Wisdom makes no guarantees as to the accuracy of its advisors and is for entertainment purposes only. However, we have a very stringent process to screen and onboard advisors to ensure that they meet Zest Wisdom's quality standards."
  },
  {
    question: "I contacted support but didn't receive a reply. When will someone reply?",
    answer: "If you have contacted our support for more than 2 business days and haven't received a response, please check in your spam folder. We strive to answer all emails in a timely manner, and sometimes they end up in your spam or junk folder."
  },
  {
    question: "Why won't my payment go through? Why can't I purchase credits?",
    answer: "You may need to contact PayPal, and/or your bank or card holder, and let them know that this is an authorized payment."
  },
  {
    question: "I am unhappy with the advice I received. Can I get a refund?",
    answer: "We're sorry that you weren't happy with the advice that you received. We do not give refunds on Zest Wisdom since we do not guarantee what the advisors say, advice that they give or circumstance outcomes or predictions. Zest Wisdom is for entertainment purposes only. With the live readings, you have the option to hang up at any time. If you feel an advisor isn't connecting to your situation, feel free to hang up and try one of our other talented advisors. If there are technical issues or quality issues with your order, please do contact support and let us know."
  },
  {
    question: "Can I transfer credits to or from Zest Wisdom?",
    answer: "Unfortunately, there is no way to transfer your credit balance from one account to another at this time. Accounts are created separately and are not linked to one another."
  },
  {
    question: "What will appear on my debit/credit card statement?",
    answer: "Your statement will be charged under World of Metaphysics Pte. Ltd, which is a registered entity in Singapore."
  }
];

onMounted(() => {
  window.scrollTo(0, 0);
});
const state = reactive({
  searchValue: "",
});
//搜索
const getSearch = (val) => {
  console.log(val, "val");
  router.push("/onlineExpert?q=" + val);
};
const goOnlineExpert = () => {
  router.push("/onlineExpert");
};
const handleChange = (val) => {
  console.log(val);
};

const isActive = (index) => {
  return activeCollapse.value === index;
}

const handleCollapseChange = (data) => {
  activeCollapse.value = data;
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 769px) {
  .middle {
    width: 100%;
    background: #f5f5f5;
    padding-bottom: 20px;

    .middle-head {
      line-height: 30px;
      padding: 0 10rem;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ffff;
      border-color: rgba(251, 249, 249, 0.1);
      font-size: 12px;

      .head-left {
        margin-left: 10px;
        color: black;
        font-size: 15px;

        .left {
          cursor: pointer;
        }
        .arrow {
          padding-left: 8px;
          padding-right: 8px;
          font-weight: 500;
        }

        .left-frite {
          color: rgb(90, 181, 209);
        }
      }

      .head-right {
        margin-right: 6px;
        margin-top: 3px;
        display: flex;

        .search-input {
          width: 300px;
          margin-bottom: 5px;
        }

        :deep(.el-input__wrapper) {
          background-color: transparent !important;
        }

        :deep(.el-input__inner) {
          color: #ffffff;
        }

        span {
          font-size: 20px;
          cursor: pointer;
          margin-left: 8px;
          margin-right: 15px;
          padding-right: 8px;
          border-right: 1px solid rgba(251, 249, 249, 0.1);
        }
      }
    }
    .content-box {
        background: transparent;
        border-radius: 20px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 10px 20px rgba(0, 0, 0, 0.05);
        width: 60%;
        margin: auto;
        margin-top: 20px;
        .head-title {
          width: 100%;
          margin-top: 20px;
          margin-bottom: 20px;
          h2 {
            color: rgb(90, 181, 209);
            font-family: "Open Sans", Arial, sans-serif;
            font-size: 24px;
          }
        }

        :deep(.el-collapse-item__header) {
          border-bottom: 0 none;
          // padding-left: 25px;
          font-family: "Open Sans", Arial, sans-serif;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          height: 55px;
          padding: 0px 25px 0px 25px;
        }

        :deep(.el-collapse-item__header:hover) {
          background-color: rgb(189, 219, 228);
        }

        :deep(.selected .el-collapse-item__header) {
          background-color: rgb(90, 181, 209);
        }

        :deep(.el-collapse-item__content) {
          padding: 10px 25px 10px 25px;
          align-items: center;
          font-family: "Open Sans", Arial, sans-serif;
          font-weight: normal;
          font-size: 16px;
          white-space: pre-line;
        }

        .faq-list {
          .box-collapse {
            border: none;
            .custom-collapse-item {
              margin-bottom: 10px;
              .font-answer {
                margin: auto auto;
              }
            }
          }
        }
      }
  }
}

/* 移动端样式 */
@media screen and (max-width: 768px) {
  .middle {
    width: 100%;
    background: #f5f5f5;
    padding-bottom: 20px;

    .middle-head {
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ffff;
      border-color: rgba(251, 249, 249, 0.1);
      font-size: 12px;

      .head-left {
        margin-left: 10px;
        color: black;
        font-size: 15px;

        .left {
          cursor: pointer;
        }

        .arrow {
          padding-left: 5px;
          padding-right: 5px;
          font-weight: 500;
        }

        .left-frite {
          color: rgb(90, 181, 209);
        }
      }

      .head-right {
        margin-right: 6px;
        margin-top: 3px;
        display: flex;

        .search-input {
          margin-bottom: 5px;
        }

        :deep(.el-input__wrapper) {
          background-color: transparent !important;
        }

        :deep(.el-input__inner) {
          color: #ffffff;
        }

        span {
          font-size: 20px;
          cursor: pointer;
          margin-left: 8px;
          margin-right: 15px;
          padding-right: 8px;
          border-right: 1px solid rgba(251, 249, 249, 0.1);
        }
      }
    }

    .content-box {
      background: transparent;
      border-radius: 20px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 10px 20px rgba(0, 0, 0, 0.05);
      width: 90%;
      margin: auto;
      margin-top: 20px;
      .head-title {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        h2 {
          color: rgb(90, 181, 209);
          font-family: "Open Sans", Arial, sans-serif;
          font-size: 22px;
        }
      }

      :deep(.el-collapse-item__header) {
        border-bottom: 0 none;
        font-family: "Open Sans", Arial, sans-serif;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.5;
        height: 55px;
        padding: 5px 20px 5px 20px;
      }

      :deep(.el-collapse-item__header:hover) {
        background-color: rgb(189, 219, 228);
      }

      :deep(.selected .el-collapse-item__header) {
        background-color: rgb(90, 181, 209);
      }

      :deep(.el-collapse-item__content) {
        padding: 10px 25px 10px 25px;
        align-items: center;
        font-family: "Open Sans", Arial, sans-serif;
        font-weight: normal;
        font-size: 16px;
        white-space: pre-line;
      }

      .faq-list {
        .box-collapse {
          border: none;
          .custom-collapse-item {
            margin-bottom: 10px;
            .font-answer {
              margin: auto auto;
            }
          }
        }
      }
    }
  }
}
</style>
